import React, { useEffect, useState } from "react"
import { navigate, graphql } from "gatsby"
import Helmet from "react-helmet"

const IndexPage = ({data}) => {

  const [loading, setIsLoading] = useState(false)

  function isCountryCodefromAPACCountries(countryCode) {
    const apacCountries = ["AU","BD","BN","BT","CC","CX","FJ","FM","GU","HK","ID","IN","IO","KH","KI","KP","KR","LA","LK","MH","MM","MN","MO","MP","MV","MY","NF","NP","NR","NU","NZ","PG","PH","PK","PN","PW","SB","SG","TH","TK","TL","TO","TV","TW","UM","VN","VU","WS"]
    return apacCountries.includes(countryCode)
  }

  useEffect(() => {
    async function redirectRegion() {
      let regionSlug = "be"
      const location = await fetch(
        `https://get.geojs.io/v1/ip/country.json`
      ).then(res => res.json())
      if (isCountryCodefromAPACCountries(location.country)) {
        regionSlug = "apac"
      } else {
        for (const region of data.allContentfulRegion.nodes) {
          if (region.slug.toUpperCase() === location.country) {
            regionSlug = region.slug
            break
          }
        }
      }
      setIsLoading(false)
      navigate(`${regionSlug}`)
    }
    redirectRegion()
  }, [loading.loading])

  return (
    <>
      <Helmet htmlAttributes={{ class: "home" }} />
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    allContentfulRegion {
      nodes {
        slug
      }
    }
  }
`

export default IndexPage
